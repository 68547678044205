<template>
	<div class="user-page" v-loading="loading">
		<div v-show="!loading">
			<div class="col cc user-info group">
				<el-image fit="contain" :src="userInfo.avatar" class="avatar"></el-image>
				<div class="username">{{ userInfo.username || userInfo.nickname }}</div>
				<div class="row cc auth" v-if="userInfo.is_cert == 1">
					<el-image fit="contain" :src="require('@/pc/assets/user/auth.png')" class="img"></el-image>
					<div class="status">{{ userInfo.major_name }}</div>
				</div>
				<div class="row cc auth none" v-else>
					<el-image fit="contain" :src="require('@/pc/assets/user/auth.png')" class="img"></el-image>
					<div class="status">未认证</div>
				</div>
				<template v-if="isMine">
					<el-popover placement="bottom" trigger="click">
						<div class="col cc pop-qr-view">
							<div class="row cc wechat">
								<el-image fit="contain" :src="require('@/pc/assets/login/wechat1.png')" class="wechat-img"></el-image>
								<span>微信扫描二维码</span>
							</div>
							<el-image fit="contain" :src="$store.getters.qrcode('user/edit')" class="qrcode-img"></el-image>
							<div class="row cc tips">编辑个人资料</div>
						</div>
						<template slot="reference">
							<el-button type="primary" round class="row cc btn">
								<i class="el-icon-edit"></i>
								<span>编辑资料</span>
							</el-button>
						</template>
					</el-popover>
				</template>
				<template v-else>
					<el-button type="primary" round class="row cc btn" :loading="subLoading" @click="tapSub()" v-if="userInfo.is_focus === '0'">
						<i class="el-icon-plus"></i>
						<span>关注</span>
					</el-button>
					<el-button type="info" round class="row cc btn" :loading="subLoading" @click="tapSub()" v-else><span>取消关注</span></el-button>
				</template>
			</div>
			<div class="col group">
				<div class="green-title">互动详情</div>
				<div class="row statistics a">
					<div class="sub-box">
						<div class="col cc sub">
							<div class="row cc num">{{ userInfo.agree_num || 0 }}</div>
							<div class="row cc label">获赞数</div>
						</div>
					</div>
					<div class="sub-box">
						<div class="col cc sub">
							<div class="row cc num">{{ userInfo.accept_answer_scale }}%</div>
							<div class="row cc label">回答被采纳</div>
						</div>
					</div>
					<div class="sub-box">
						<div class="col cc sub">
							<div class="row cc num">{{ userInfo.focus_que_num || 0 }}</div>
							<div class="row cc label">问题被关注</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col group" v-if="userInfo.is_cert == 1">
				<div class="green-title">用户详情</div>
				<div class="col detail">
					<div class="row detail-row" v-if="userInfo.school_name">
						<el-image fit="contain" :src="require('@/pc/assets/user/detail-1.png')" class="icon1"></el-image>
						<div class="row info">
							<div class="label">就读院校：</div>
							<div class="text">{{ userInfo.school_name }}</div>
						</div>
					</div>
					<div class="row detail-row" v-if="userInfo.unit_name">
						<el-image fit="contain" :src="require('@/pc/assets/user/detail-2.png')" class="icon2"></el-image>
						<div class="row info">
							<div class="label">工作单位：</div>
							<div class="text">{{ userInfo.unit_name }}</div>
						</div>
					</div>
					<div class="row detail-row" v-if="userInfo.city">
						<el-image fit="contain" :src="require('@/pc/assets/user/detail-3.png')" class="icon3"></el-image>
						<div class="row info">
							<div class="label">所在地区：</div>
							<div class="text">{{ userInfo.city }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col group">
				<div class="green-title">擅长领域</div>
				<div class="row tags">
					<div class="row cc tag a" v-for="(item, index) in userInfo.tag_names" :key="index">{{ item }}</div>
				</div>
			</div>
			<div class="col group" v-if="isMine">
				<div class="green-title">用户权益</div>
				<div class="row statistics a">
					<el-popover placement="top" trigger="click" style="flex: 1;">
						<div class="col cc pop-qr-view">
							<div class="row cc wechat">
								<el-image fit="contain" :src="require('@/pc/assets/login/wechat1.png')" class="wechat-img"></el-image>
								<span>微信扫描二维码</span>
							</div>
							<el-image fit="contain" :src="$store.getters.qrcode('income')" class="qrcode-img"></el-image>
							<div class="row cc tips">查看答题收益</div>
						</div>
						<template slot="reference">
							<div class="row cc sub-box">
								<div class="col cc sub">
									<div class="row cc num">¥{{ userInfo.money || 0 }}</div>
									<div class="row cc label">答题收益</div>
								</div>
								<i class="el-icon-arrow-right"></i>
							</div>
						</template>
					</el-popover>
					<el-popover placement="top" trigger="click" style="flex: 1;">
						<div class="col cc pop-qr-view">
							<div class="row cc wechat">
								<el-image fit="contain" :src="require('@/pc/assets/login/wechat1.png')" class="wechat-img"></el-image>
								<span>微信扫描二维码</span>
							</div>
							<el-image fit="contain" :src="$store.getters.qrcode('integral')" class="qrcode-img"></el-image>
							<div class="row cc tips">查看积分余额</div>
						</div>
						<template slot="reference">
							<div class="row cc sub-box">
								<div class="col cc sub">
									<div class="row cc num">{{ userInfo.point || 0 }}</div>
									<div class="row cc label">积分余额</div>
								</div>
								<i class="el-icon-arrow-right"></i>
							</div>
						</template>
					</el-popover>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			loading: true,
			userInfo: {},
			currentUserInfo: {},
			subLoading: false
		};
	},
	computed: {
		isMine() {
			return this.userInfo.id == this.currentUserInfo.id;
		}
	},
	async created() {
		let userInfo = await this.$service.post('user_info/detail', { user_id: this.$route.query.user_id || '' });
		this.userInfo = userInfo.data;
		let currentUserInfo = await this.$service.post('user_info/detail');
		this.currentUserInfo = currentUserInfo.data;
		this.loading = false;
	},
	methods: {
		async tapSub() {
			this.subLoading = true;
			try {
				let item = this.userInfo;
				if (item.is_focus === '0') {
					await this.$service.post('question/focusUser', { focus_user_id: item.id });
					item.is_focus = '1';
				} else {
					await this.$service.post('question/delFocusUser', { focus_user_id: item.id });
					item.is_focus = '0';
				}
			} catch {
				this.subLoading = false;
			}
			this.subLoading = false;
		}
	}
};
</script>

<style scoped lang="less">
@import './userInfo.less';
</style>
